export const decodeToken = (token) => {
  try {
    const a = token.split('.')[1].replace('-', '+').replace('_', '/');
    const decode = decodeURIComponent(atob(a).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(decode);
  } catch (e) {
    return null;
  }
}
