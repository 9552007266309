import Cookies from 'js-cookie';
import { useState } from 'react';
import { decodeToken } from 'src/utilities/token';
import { getDomain } from 'src/utilities/url';


export const getCredentials = () => {
  const credentials = Cookies.get('credentials');
  return credentials && JSON.parse(credentials);
};

export const setCredentials = credentials => {
  const json = JSON.stringify(credentials)
  Cookies.set('credentials', json, { domain: getDomain() });

};

export const removeCredentials = () => {
  Cookies.remove("credentials", { domain: getDomain() });
};

export const getUserFromToken = () => {
  const credentials = getCredentials();
  if (credentials) {
    const tokenData = decodeToken(credentials.token);
    if (tokenData) {
      return tokenData;
    }
  }
  return null;
};

export default function useCredentials() {

  const [credentials, saveCredentials] = useState(getCredentials());

  return {
    credentials,
    removeCredentials,
    setCredentials: (credentials) => {
      setCredentials(credentials)
      saveCredentials(getCredentials());
    }
  };
}
