import { CAlert, CSpinner } from '@coreui/react';
import styles from './loading.module.scss';

export const Loading = ({ fullscreen = false, loading = false, message = "لطفا صبر کنید...", className }) => {

  return (
    <>
      {
        loading &&
        <div className={`${className} ${fullscreen ? styles.loading_overlay : ''}`}>
          <CAlert color='light' className={'d-flex align-items-center text-dark'}>
            <CSpinner className='me-3' />
            <span>{message}</span>
          </CAlert>
        </div>
      }
    </>
  );
}

export default Loading;

