import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { currencies } from './currencies';
import { panel } from './panel';
import { toast } from './toast';

const root = combineReducers({ currencies, panel, toast });

const store = createStore(root, applyMiddleware(thunkMiddleware));

export default store;
