import { panelConstants } from "./panel.constants";

export const updateSidebarShow = value => {
  return {
    type: panelConstants.SET_PANEL_CONFIG,
    sidebarShow: value
  };
};

export const updateSidebarUnfoldable = value => {
  return {
    type: panelConstants.SET_PANEL_CONFIG,
    sidebarUnfoldable: value
  };
};

export const showLoading = () => {
  return {
    type: panelConstants.INCREASE_LOADING
  };
};

export const hideLoading = () => {
  return {
    type: panelConstants.DECREASE_LOADING
  };
};
