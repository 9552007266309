import React from 'react';
import { Navigate } from 'react-router-dom';
import useCredentials from 'src/stores/useCredentials';

export const PrivateRoute = ({ component: Component }) => {

  const { credentials } = useCredentials();

  return (
    credentials
      ?
      <Component />
      :
      <Navigate to='/login' />
  );
};

export default PrivateRoute;
