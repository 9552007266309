import { panelConstants } from "./panel.constants";

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  loading: 0
}

export const panel = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case panelConstants.SET_PANEL_CONFIG:
      return { ...state, ...rest }
    case panelConstants.INCREASE_LOADING:
      return { ...state, loading: state.loading + 1 }
    case panelConstants.DECREASE_LOADING:
      return { ...state, loading: state.loading - 1 }
    default:
      return state
  }
}

export default panel;
