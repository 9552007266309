import { history } from "./history";

export const getDomain = () => {
  if (window.location.port.length > 0) {
    return window.location.hostname;
  }
  return `${window.location.hostname.match(/\w*\.\w*$/gi)[0]}`;
}

export const isAbsoluteURL = (url) => {
  return url.startsWith("https://") || url.startsWith("http://")
}

export const APIBaseURL = process.env.REACT_APP_API_BASE;

export const SocketBaseURL = process.env.REACT_APP_SOCKET_BASE;

export const getQueryParam = (property, type = "string", defaultValue = '') => {
  const currentParams = new URLSearchParams(window.location.search);
  const value = currentParams.get(property);
  switch (type) {
    case "boolean":
      return value === 'true';
    case "int":
      return parseInt(value) || defaultValue;
    case "float":
      return parseFloat(value) || defaultValue;
    case "string":
      return value || defaultValue;
    default:
      return value || defaultValue;
  }
}

export const updateQueryParams = (params, push) => {
  const currentParams = new URLSearchParams(window.location.search);
  for (const key in params) {
    const value = params[key];
    if (!value) {
      currentParams.delete(key);
    } else {
      currentParams.set(key, value);
    }
  }
  if (push) {
    history.push({ search: currentParams.toString() });
  } else {
    history.replace({ search: currentParams.toString() });
  }
};
