import { currenciesConstants } from "./currencies.constants";

const initialState = { currencies: null }

export const currencies = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case currenciesConstants.SET_CURRENCIES: {
      const updated = { ...state, ...rest };
      return updated;
    }
    default: {
      return state
    }
  }
}

export default currencies;
