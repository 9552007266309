import { toastConstants } from "./toast.constants";

const initialState = {
  toast: 0
}

export const toast = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case toastConstants.SET_TOAST_MESSAGE:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default toast;
